import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import { ReactNode } from "react";

type Props = {
  color?: string;
  title?: ReactNode;
  subtitleTop?: string;
  subtitleBottom?: ReactNode;
  content?: ReactNode;
};
const ResultSection: React.FC<Props> = (props) => {
  return (
    <IonCard >
      <IonCardHeader color={props.color}>
        {props.subtitleTop && <IonCardSubtitle>{props.subtitleTop}</IonCardSubtitle>}
        {props.title && <IonCardTitle>{props.title}</IonCardTitle>}
        {props.subtitleBottom && <IonCardSubtitle>{props.subtitleBottom}</IonCardSubtitle>}
      </IonCardHeader>
      {props.content && <IonCardContent>{props.content}</IonCardContent>}
    </IonCard>
  );
};
export default ResultSection;